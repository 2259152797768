<template>
  <b-container class="mt-5">
    <b-list-group>
      <b-list-group-item v-for="product in stockAlertProducts" :key="product.reference">
        <b-row>
          <b-col sm="8">
            <h5 class="mb-1" style="color:red">{{ product.name }}</h5>
            <p>Référence : {{ product.reference }}</p>
          </b-col>
          <b-col sm="4" class="text-right">
            <h5 style="color:red">{{ product.stock }}</h5>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-container>
</template>

<script>
import axios from 'axios'
import {
  BContainer, BListGroup, BListGroupItem, BRow,
  BCol,

} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
  data() {
    return {
      stockAlertProducts: [],
    }
  },
  mounted() {
    this.fetchStockAlertProducts()
  },
  methods: {
    async fetchStockAlertProducts() {
      const response = await axios.get('/products/get_stock_alert/')
      this.stockAlertProducts = response.data
    },
  },
}
</script>
